import OsirisService from './BaseOsirisService';

const BASE_MERCHANT = 'merchant';

class MerchantService extends OsirisService {
    async create(data) {
        try {
            return await this.api.post(BASE_MERCHANT, data);
        } catch ({ response }) {
            return response;
        }
    }

    async index() {
        try {
            return await this.api.get(BASE_MERCHANT);
        } catch (response) {
            return response;
        }
    }

    async show({ id }) {
        try {
            return await this.api.get(`${BASE_MERCHANT}/${id}`);
        } catch ({ response }) {
            return response;
        }
    }

    async update(id, data) {
        try {
            return await this.api.put(`${BASE_MERCHANT}/${id}`, data);
        } catch ({ response }) {
            return response;
        }
    }

    async disable(id) {
        try {
            return await this.api.delete(`${BASE_MERCHANT}/${id}`);
        } catch ({ response }) {
            return response;
        }
    }
}

export default new MerchantService();
