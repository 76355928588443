<template>
  <b-row>
    <b-col
      class="text-center"
    >
      <h4> {{ items.total || 0 }} </h4>
      <h4> TOTAL </h4>
    </b-col>

    <b-col
      class="text-center"
    >
      <h4> {{ items.active_ecs || 0 }} </h4>
      <h4> ATIVOS </h4>
    </b-col>

    <b-col
      class="text-center"
    >
      <h4> {{ items.inactive_ecs || 0 }} </h4>
      <h4> INATIVOS </h4>
    </b-col>

    <b-col
      class="text-center"
    >
      <h4> {{ items.active_opt_ins || 0 }} </h4>
      <h4> OPT-INS ATIVOS </h4>
    </b-col>

    <b-col
      class="text-center"
    >
      <h4> {{ items.active_endorsements || 0 }} </h4>
      <h4> CONTRATOS ATIVOS </h4>
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue';

export default {
    components: {
        BRow,
        BCol,
    },
    props: {
        items: {
            default: () => {},
            type: Object,
        },
    },
};
</script>
