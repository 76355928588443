<template>
  <div class="d-flex flex-column text-center">
    <h4> {{ info }} </h4>
    <h4> {{ title.toUpperCase() }} </h4>
  </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },

        info: {
            type: String,
            default: '',
        },
    },
};
</script>
