<template>
  <b-container
    fluid
  >

    <b-card>
      <h2 class="text-center mb-3">
        Estabelecimentos Comerciais
      </h2>
      <MerchantSummary :items="merchantSummary" />
    </b-card>

    <b-card>
      <h2 class="text-center">
        Contratos
      </h2>
      <b-row class=" mt-3">
        <b-col
          v-for="item in summaryEndorsementFormatted"
          :key="item.title"
        >
          <SummaryItem
            :info="item.info"
            :title="item.title"
          />
        </b-col>
      </b-row>

    </b-card>

  </b-container>
</template>

<script>
import {
    BContainer, BCard, BRow, BCol,
} from 'bootstrap-vue';

import MerchantSummary from '@/views/pages/CreditPlatform/Merchants/Summary.vue';
import SummaryItem from '@/views/components/SummaryItem.vue';

import formatValue from '@/filters/value';

import MerchantService from '@/services/osiris/MerchantService';
import EndorsementService from '@/services/osiris/EndorsementService';

export default {
    components: {
        BContainer,
        BCard,
        SummaryItem,
        BRow,
        BCol,
        MerchantSummary,
    },

    data() {
        return {
            summaryEndorsement: { ...this.summary },
            merchantSummary: {},
        };
    },

    computed: {
        summaryEndorsementFormatted() {
            if (this.summaryEndorsement.total === undefined) return [];
            return [
                {
                    info: String(this.summaryEndorsement.total),
                    title: 'N Contratos Aprovados',
                },
                {
                    info: String(this.summaryEndorsement.due_contracts),
                    title: 'Ativos',
                },
                {
                    info: String(this.summaryEndorsement.expired_contracts),
                    title: 'Finalizados',
                },
                {
                    info: formatValue(this.summaryEndorsement.due_amount),
                    title: 'A Expirar',
                },
                {
                    info: formatValue(this.summaryEndorsement.expired_amount),
                    title: 'Expirado',
                },
            ];
        },
    },

    async mounted() {
        this.isLoading(true);
        await this.getSummary(),
        await this.getMerchantSummary();
        this.isLoading(false);
    },

    methods: {
        async getSummary() {
            const { data } = await EndorsementService.getSummary();
            this.summaryEndorsement = data;
        },

        async getMerchantSummary() {
            const { data } = await MerchantService.index();
            data.summary.active_endorsements = this.summaryEndorsement.due_contracts;
            this.merchantSummary = data.summary;
        },
    },
};
</script>
